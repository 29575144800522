import {
  STORE_TYPES,
  FEEDBACK_INTEGRATION_SOURCES,
  EMAIL_INTEGRATION_SOURCES,
  BILLING_INTEGRATION_SOURCES,
} from '@/helpers/interfaces'
import { FORM_INTEGRATION_SOURCES } from '@/helpers/constants'

import type { Integration } from '@/store/integrations'

export const demoStoreIntegrations: Integration[] = STORE_TYPES.map((type) => ({
  id: `demo-${type}`,
  name: `${type} demo`,
  platform: type,
  automationList: [],
  createdAt: new Date().toLocaleDateString(),
  status: {
    status: 'active',
  },
  types: ['store'],
}))

export const demoEmailIntegrations: Integration[] = EMAIL_INTEGRATION_SOURCES.map((type) => {
  const integrationData: Integration = {
    id: `demo-${type}`,
    name: `${type} demo`,
    platform: type,
    automationList: [],
    createdAt: new Date().toLocaleDateString(),
    status: {
      status: 'active',
    },
    types: ['email'],
  }

  if (type === 'klaviyo') {
    return {
      ...integrationData,
      writeDirection: {
        status: {
          status: 'disabled',
        },
        testStatus: {
          status: 'success',
          initiatedAt: new Date(),
        },
        syncHistory: {
          initiatedAt: new Date(),
          finishedAt: null,
          numberOfSyncedImpactActions: null,
        },
      },
    }
  } else {
    return { ...integrationData }
  }
})

export const demoFeedbackIntegrations: Integration[] = FEEDBACK_INTEGRATION_SOURCES.map((type) => ({
  id: `demo-${type}`,
  name: `${type} demo`,
  platform: type,
  automationList: [],
  createdAt: new Date().toLocaleDateString(),
  status: {
    status: 'active',
  },
  types: ['feedback'],
}))

export const demoFormIntegrations: Integration[] = FORM_INTEGRATION_SOURCES.map((type) => ({
  id: `demo-${type}`,
  name: `${type} demo`,
  platform: type,
  automationList: [],
  createdAt: new Date().toLocaleDateString(),
  status: {
    status: 'active',
  },
  types: ['form'],
}))

export const demoBillingIntegrations: Integration[] = BILLING_INTEGRATION_SOURCES.map((type) => ({
  id: `demo-${type}`,
  name: `${type} demo`,
  platform: type,
  automationList: [],
  createdAt: new Date().toLocaleDateString(),
  status: {
    status: 'active',
  },
  types: ['billing'],
}))

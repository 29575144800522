import { defineComponent } from 'vue'
import type { CreateIntegrationRequestBody, CustomIntegrationTriggerType } from '@api/index'
import VueI18n from 'vue-i18n'
import store from '@/store'
import type { Notification } from '@/store/notification'
import type {
  AutomationTrigger,
  Integration,
  IntegrationOption,
  IntegrationPlatform,
} from '@/store/integrations'
import TranslateResult = VueI18n.TranslateResult
import { API_ERRORS } from '@/helpers/constants'

export const IntegrationsMixin = defineComponent({
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async setupStore(
      integration: IntegrationOption,
      actionOnSuccess: (arg: IntegrationOption) => void,
      actionOnClose?: () => void,
    ) {
      this.loading = true
      try {
        const platform = integration.slug
        const rutterInstance = window.Rutter.create({
          publicKey: process.env.VUE_APP_RUTTER_PUBLIC_KEY,
          onSuccess: async (publicToken) => {
            // Send publicToken to your backend and exchange
            // https://docs.rutterapi.com/reference#exchange-tokens
            try {
              await this.createIntegration({ platform, publicToken })
              actionOnSuccess(integration)
            } catch (error) {
              this.loading = false
              console.error('setupStore - createIntegration', error)
            }
          },
          onError: (error) => {
            console.error(error)
            store.dispatch(
              'notification/notify',
              {
                text: this.$t('CommonUi.error_generic'),
                isError: true,
                isClosable: true,
                buttonText: 'close',
              } as Notification,
              { root: true },
            )
          },
          onExit: function (error) {
            console.error(error)
            if (actionOnClose) actionOnClose()
          },
        })
        rutterInstance.open({
          platform,
        })
        // this.loading = true
        await this.setIntegrations()
      } catch (error) {
        console.error(error)
        store.dispatch('notification/notify', {
          text: this.$t('CommonUi.error_generic'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
    },
    setupIntegration(
      userId: string,
      integration: IntegrationOption,
      actionOnSuccess: (arg: IntegrationOption) => void,
      actionOnClose?: () => void,
      actionOnOpen?: () => void,
      helpText?: TranslateResult | string,
    ) {
      window.HotGlue.setListener({
        async onSourceLinked() {
          try {
            if (integration.activeNumber === 0) {
              await store.dispatch('createIntegration', {
                platform: integration.slug,
                externalId: integration.provider.externalId,
              })
            }

            actionOnSuccess(integration)
          } catch (error) {
            if (actionOnClose) actionOnClose()
          }
          window.HotGlue.close()
        },
        onWidgetOpen() {
          if (actionOnOpen) actionOnOpen()
        },
        onWidgetClose() {
          if (actionOnClose) actionOnClose()
        },
        onSourceLinkCanceled() {
          if (actionOnClose) actionOnClose()
        },
      })
      // Hotglue adds '-v2' to the Stripe integration, making the platform 'stripe-v2',
      // but we only accept 'stripe', so we need to add the '-v2' back to the slug.
      const platform = (integration.slug as string) === 'stripe' ? 'stripe-v2' : integration.slug

      window.HotGlue.link(this.accountId, integration.provider.externalId, platform, false, {
        helperText: `<a href="${
          integration.url || '#'
        }" rel="noopener noreferrer nofollow" target="_blank">${helpText ?? this.t('help')}</a>`,
      })
    },
    getIntegrationPlatformName(
      integrationPlatform: IntegrationPlatform | CustomIntegrationTriggerType | null,
    ) {
      switch (integrationPlatform) {
        case 'mailchimp':
          return 'Mailchimp'
        case 'klaviyo':
          return 'Klaviyo'
        case 'shopify':
          return 'Shopify'
        case 'activecampaign':
          return 'Active Campaign'
        case 'amazon':
          return 'Amazon'
        case 'ebay':
          return 'Ebay'
        case 'campaign-monitor':
          return 'Campaign Monitor'
        case 'judgeme':
          return 'Judge.me'
        case 'magento':
          return 'Magento'
        case 'mailgun':
          return 'Mailgun'
        case 'mailshake':
          return 'Mailshake'
        case 'omnisend':
          return 'Omnisend'
        case 'prestashop':
          return 'Prestashop'
        case 'reviewsio':
          return 'Reviews.io'
        case 'sendinblue':
          return 'Sendinblue'
        case 'square':
          return 'Square'
        case 'squarespace':
          return 'Squarespace'
        case 'stamped':
          return 'Stamped.io'
        case 'woocommerce':
          return 'Woo Commerce'
        case 'yotpo':
          return 'Yotpo'
        case 'affiliate':
          return 'Affiliate'
        case 'review':
          return 'Review'
        case 'partner':
          return 'Partner'
        case 'subscriber':
          return 'Subscriber'
        case 'subscription':
          return 'Subscription'
        case 'transaction':
          return 'Transaction'
        case 'order':
          return 'Order'
        case 'zapier':
          return 'Zapier'
        case 'form completion':
          return 'Form Completion'
        case 'other':
          return 'Other'
        case 'custom':
          return 'Custom'
        case 'integrately':
          return 'Integrately'
        case 'typeform':
          return 'Typeform'
        case 'surveymonkey':
          return 'SurveyMonkey'
        case 'etsy':
          return 'Etsy'
        case 'stripe':
          return 'Stripe'
        case 'rebuy':
          return 'Rebuy'
        default:
          console.error(`${integrationPlatform} does not exist.`)
      }
    },
    getAutomationIcon(trigger: AutomationTrigger) {
      switch (trigger) {
        case 'offsetByPercentageOrder':
          return 'mdi-percent-box'
        case 'offsetBySpend':
          return 'mdi-cash-multiple'
        case 'offsetByStoreRevenue':
          return 'mdi-storefront-outline'
        case 'offsetPerOrder':
          return 'mdi-cart-outline'
        case 'offsetPerProduct':
          return 'mdi-gamepad-circle-left'
        case 'offsetAllProducts':
          return 'mdi-gamepad-circle'
        case 'perCompanyReview':
          return 'mdi-domain'
        case 'perProductReview':
          return 'mdi-message-draw'
        case 'perSmsSubscriber':
          return 'mdi-cellphone-nfc'
        case 'perSubscriber':
          return 'mdi-email-outline'
        case 'perCompletedForm':
          return 'mdi-text-box-edit-outline'
        default:
          return 'mdi-puzzle-outline'
      }
    },
    async mountHotGlue() {
      if (window.HotGlue.hasMounted() === false) {
        await window.HotGlue.mount(
          {
            api_key: process.env.VUE_APP_HOTGLUE_API_KEY,
            env_id: process.env.VUE_APP_HOTGLUE_ENV_ID,
            api_url: 'https://hg-api.getgreenspark.com',
          },
          {
            breadcrumbs: false,
            hideBackButtons: true,
          },
        )
      }
    },
    t(key: string) {
      return this.$t(`IntegrationsMixin.${key}`)
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
    async createIntegration(
      createIntegrationRequestBody: CreateIntegrationRequestBody,
    ): Promise<void> {
      try {
        await this.$store.dispatch('createIntegration', createIntegrationRequestBody)
      } catch (error) {
        if (error === API_ERRORS.STORE_ALREADY_CONNECTED) {
          store.dispatch('notification/notify', {
            text: this.$t('CommonUi.store_already_connected'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification)
          this.$router.push('/')
          return
        }

        throw error
      }
    },
  },
  computed: {
    userStores(): Integration[] {
      return this.$store.getters['getUserStores']
    },
    accountId(): string {
      return this.$store.getters['getAccountId']
    },
  },
})

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f0a7428"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "upgrade-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "price-difference-wrapper"
}
const _hoisted_6 = { class: "price-title-wrapper" }
const _hoisted_7 = { class: "upgrade-icon-wrapper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "plan-name" }
const _hoisted_10 = { class: "plan-price" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 5,
  class: "error-message"
}
const _hoisted_14 = { class: "issue-list" }
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    class: "change-plan-popup",
    "model-value": _ctx.isOpen,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = 
      (value) => {
        _ctx.isOpen = value
        if (!value) _ctx.$emit('close')
      }
    ),
    fullscreen: _ctx.isMobile,
    "max-width": "695"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.isUpgrade ? _ctx.t('upgrade_title') : _ctx.t('downgrade_title')), 1),
            _createElementVNode("button", {
              class: "close-button",
              onClick: _cache[0] || (_cache[0] = 
            () => {
              _ctx.isOpen = false
              _ctx.$emit('close')
            }
          )
            })
          ]),
          (_ctx.isUpgrade)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                class: "upgrade-text",
                innerHTML: 
          _ctx.t(_ctx.isTrial ? 'upgrade_from_trial.subtitle' : 'upgrade.subtitle', {
            currentPlan: _ctx.currentPlanType && _ctx.getPlanName(_ctx.currentPlanType),
            selectedPlan: _ctx.selectedPlanType && _ctx.getPlanName(_ctx.selectedPlanType),
          })
        
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("p", {
                key: 1,
                class: "upgrade-text",
                innerHTML: 
          _ctx.t(
            _ctx.isTrial ? 'downgrade_from_trial.subtitle' : 'downgrade.subtitle',
            _ctx.currentPlanType &&
              _ctx.selectedPlanType && {
                currentPlan: _ctx.getPlanName(_ctx.currentPlanType),
                selectedPlan: _ctx.getPlanName(_ctx.selectedPlanType),
              },
          )
        
              }, null, 8, _hoisted_4)),
          (_ctx.selectedPlanType)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, [
                    _createElementVNode("img", {
                      class: "upgrade-icon",
                      src: 
                require(_ctx.isUpgrade ? '@/assets/icons/upgrade.svg' : '@/assets/icons/downgrade.svg')
              
                    }, null, 8, _hoisted_8)
                  ]),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.getPlanName(_ctx.selectedPlanType)) + " " + _toDisplayString(_ctx.t('plan')), 1)
                ]),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.displayPrice), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isUpgrade)
            ? (_openBlock(), _createElementBlock("p", {
                key: 3,
                class: "upgrade-disclaimer",
                innerHTML: 
          _ctx.t(
            _ctx.isTrial
              ? _ctx.isSelectedPlanYearly
                ? 'upgrade_from_trial.disclaimer_per_year'
                : 'upgrade_from_trial.disclaimer_per_month'
              : 'upgrade.disclaimer',
            {
              totalAmount: _ctx.displayPrice,
            },
          )
        
              }, null, 8, _hoisted_11))
            : (_openBlock(), _createElementBlock("p", {
                key: 4,
                class: "upgrade-disclaimer",
                innerHTML: 
          _ctx.t(
            _ctx.isTrial
              ? _ctx.isSelectedPlanYearly
                ? 'downgrade_from_trial.disclaimer_per_year'
                : 'downgrade_from_trial.disclaimer_per_month'
              : 'downgrade.disclaimer',
            {
              totalAmount: _ctx.displayPrice,
            },
          )
        
              }, null, 8, _hoisted_12)),
          (_ctx.isDowngradeDisabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("ul", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.downgradeIssues, ({ issue, currentAmount, maxAmount }, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "issue-list-item"
                    }, [
                      _createVNode(_component_v_icon, {
                        size: "24px",
                        class: "issue-icon"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" mdi-information-outline ")
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", null, _toDisplayString(_ctx.t(_ctx.snakeCase(issue), { currentAmount, maxAmount })), 1)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isDowngradeDisabled)
            ? (_openBlock(), _createBlock(_component_v_checkbox, {
                key: 6,
                modelValue: _ctx.checkbox,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkbox) = $event)),
                color: "green",
                "hide-details": "",
                rules: [_ctx.rules.required],
                class: "terms-checkbox"
              }, {
                label: _withCtx(() => [
                  _createVNode(_component_i18n_t, {
                    tag: "p",
                    keypath: "Signup.accept",
                    class: "terms"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: _ctx.TERMS_CONDITIONS_URL,
                        target: "_blank",
                        class: "link"
                      }, _toDisplayString(_ctx.t('terms')), 9, _hoisted_15),
                      _createElementVNode("a", {
                        href: _ctx.PRIVACY_POLICY_URL,
                        target: "_blank",
                        class: "link"
                      }, _toDisplayString(_ctx.t('privacy')), 9, _hoisted_16)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "rules"]))
            : _createCommentVNode("", true),
          _createVNode(_component_gs_button, {
            type: "primary-v2",
            size: "large",
            class: "upgrade-button",
            height: "56px",
            width: "100%",
            disabled: !_ctx.checkbox || _ctx.loading || _ctx.isDowngradeDisabled,
            uppercased: false,
            capitalized: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePlan()))
          }, {
            default: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                    key: 0,
                    indeterminate: "",
                    color: "#F9F9F9"
                  }))
                : (_ctx.isUpgrade)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.isTrial ? _ctx.t('upgrade_from_trial_button_text') : _ctx.t('upgrade_button_text')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.isTrial ? _ctx.t('downgrade_from_trial_button_text') : _ctx.t('downgrade_button_text')), 1))
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value", "fullscreen"]))
}
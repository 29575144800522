<template>
  <section class="select-forms-impact-source">
    <p :class="['select-forms-impact-source__title', { onboarding: isOnboarding }]">
      {{ t('title') }}
    </p>

    <v-radio-group v-model="radioGroupValue" @update:model-value="onRadioGroupChange">
      <v-radio :key="'all'" color="green" :value="'all'">
        <template v-slot:label>
          <span class="label">
            {{ t('all') }}
          </span>
        </template>
      </v-radio>
      <v-radio :key="'chooseOne'" color="green" :value="'chooseOne'">
        <template v-slot:label>
          <span class="label">
            {{ t('choose_one') }}
          </span>
        </template>
      </v-radio>
    </v-radio-group>
    <gs-input
      v-model="formId"
      class="form-id-input"
      :label="t('form_id')"
      :hide-details="false"
      :rules="inputRules"
      :disabled="!inputEnabled"
      :error="error"
      @update:model-value="input"
    />
    <a :href="documentationUrl" target="_blank" class="documentation-url">
      {{ t('where_to_find_form_id') }}
    </a>
  </section>
</template>

<script lang="ts">
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { SURVEY_MONKEY_DOCUMENTATION_URL, TYPEFORM_DOCUMENTATION_URL } from '@/helpers/constants'
import type { IntegrationPlatform } from '@/store/integrations'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'SelectFormImpactSource',
  emits: ['update:model-value'],
  mixins: [RulesMixin],
  data() {
    return {
      localValue: 'all',
      radioGroupValue: 'all',
      formId: '',
    }
  },
  computed: {
    inputEnabled(): boolean {
      return this.radioGroupValue === 'chooseOne'
    },
    documentationUrl(): string {
      switch (this.platform) {
        case 'typeform':
          return TYPEFORM_DOCUMENTATION_URL
        case 'surveymonkey':
          return SURVEY_MONKEY_DOCUMENTATION_URL
        default: {
          const exhaustiveCheck = this.platform
          throw new Error(exhaustiveCheck)
        }
      }
    },
    isOnboarding(): boolean {
      return this.$route.path === '/onboarding'
    },
    inputRules(): ((value: string | number) => boolean | TranslateResult)[] {
      return this.inputEnabled ? [this.rules.required] : []
    },
  },
  created() {
    this.localValue = this.modelValue
    this.radioGroupValue = this.modelValue === 'all' ? 'all' : 'chooseOne'
    this.formId = this.modelValue === 'all' ? '' : this.modelValue
  },
  methods: {
    t(key: string) {
      return this.$t(`SelectFormImpactSource.${key}`)
    },
    onRadioGroupChange() {
      if (this.radioGroupValue === 'all') {
        this.formId = ''
        this.localValue = 'all'
      } else {
        this.localValue = this.formId
      }

      this.$emit('update:model-value', this.localValue)
    },
    input() {
      this.localValue = this.formId
      this.$emit('update:model-value', this.localValue)
    },
    onValueChange() {
      this.localValue = this.modelValue
      this.radioGroupValue = this.modelValue === 'all' ? 'all' : 'chooseOne'
      this.formId = this.modelValue === 'all' ? '' : this.modelValue
    },
  },
  props: {
    modelValue: {
      default: 'all',
      type: String,
    },
    error: {
      type: Boolean,
    },
    platform: {
      required: true,
      type: Object as PropType<IntegrationPlatform>,
    },
  },
  watch: {
    modelValue: [
      {
        handler: 'onValueChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.form-id-input {
  max-width: 340px;
}

.select-forms-impact-source__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
}

.select-forms-impact-source__title.onboarding {
  font-size: 24px;
}

.label {
  font-size: 18px;
  line-height: 26px;
  color: var(--font-color-primary);
}

.documentation-url {
  text-decoration: none;
  color: #106bf4;
}
</style>

<template>
  <div class="quick-menu">
    <v-menu min-width="200">
      <template v-slot:activator="{ props }">
        <img
          class="quick-menu-open"
          :src="require('@/assets/icons/quick-menu.svg')"
          alt="menu"
          v-bind="props"
        />
      </template>
      <div class="menu-wrapper">
        <div
          v-for="(item, a) in menuItems"
          :key="a"
          :class="item.value === 'remindNotActive' ? 'menu-item-not-active' : 'menu-item'"
          @click="quickMenuClick(item.value)"
        >
          <img :src="item.image" class="quick-menu-icon" alt="icon" />
          {{ item.label }}
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { MenuItem } from '@/helpers/interfaces'

export default defineComponent({
  name: 'QuickMenu',
  emits: ['quickMenuClick'],
  components: {},
  data() {
    const menuItems: MenuItem[] = []

    return {
      menuItems,
    }
  },
  created() {
    this.setIcons()
  },
  methods: {
    t(key: string) {
      return this.$t(`QuickMenu.${key}`)
    },
    setIcons() {
      this.menuItems = this.quickMenuItems ?? []
      for (let i = 0; i < this.menuItems.length; i++) {
        switch (this.menuItems[i].value) {
          case 'reward':
            this.menuItems[i].image = require('@/assets/icons/main-shape-dark.svg')
            break
          case 'edit':
          case 'editContact':
            this.menuItems[i].image = require('@/assets/icons/partners/edit.svg')
            break
          case 'history':
            this.menuItems[i].image = require('@/assets/icons/partners/history.svg')
            break
          case 'stop':
            this.menuItems[i].image = require('@/assets/icons/partners/stop.svg')
            break
          case 'remind':
            this.menuItems[i].image = require('@/assets/icons/partners/outgoing_mail.svg')
            break
          case 'remindNotActive':
            this.menuItems[i].image = require('@/assets/icons/partners/outgoing_mail_inactive.svg')
            break
          case 'delete':
            this.menuItems[i].image = require('@/assets/icons/delete-outline.svg')
            break
        }
      }
    },
    quickMenuClick(id) {
      this.$emit('quickMenuClick', id)
    },
  },
  props: {
    quickMenuItems: {
      type: Array as PropType<MenuItem[]>,
    },
    image: {
      type: String,
    },
    userId: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.menu-wrapper {
  background: #f9f9f9;
  padding: 4px;
}

.quick-menu-open {
  cursor: pointer;
}

.quick-menu-open:hover {
  opacity: 0.5;
}

.menu-item {
  color: #212121;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 18px;
  font-style: normal;
  font-weight: normal;
}

.menu-item:hover {
  background: #3b755f1c;
  background: rgba(175, 198, 189, 0.2);
  border-radius: 8px;
}

.menu-item-not-active {
  color: #afc6bd;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 18px;
  font-style: normal;
  font-weight: normal;
}

.quick-menu-icon {
  height: 20px;
  margin-right: 10px;
}
</style>

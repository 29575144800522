import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-585ff85f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "onboarding-view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectIntegration = _resolveComponent("SelectIntegration")!
  const _component_OnboardingPanel = _resolveComponent("OnboardingPanel")!
  const _component_StepperContent = _resolveComponent("StepperContent")!
  const _component_CustomIntegration = _resolveComponent("CustomIntegration")!
  const _component_SelectTrigger = _resolveComponent("SelectTrigger")!
  const _component_CreateApiKey = _resolveComponent("CreateApiKey")!
  const _component_CopyApiKey = _resolveComponent("CopyApiKey")!
  const _component_SelectProjects = _resolveComponent("SelectProjects")!
  const _component_InviteUserOnboard = _resolveComponent("InviteUserOnboard")!
  const _component_Stepper = _resolveComponent("Stepper")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("section", null, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Stepper, {
            modelValue: _ctx.activeStep,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.activeStep) = $event)),
            "max-value": _ctx.renderUserInviteStep ? 4 : 3
          }, {
            default: _withCtx(() => [
              _createVNode(_component_StepperContent, {
                "is-visible": _ctx.activeStep === 1,
                transition: "fade"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OnboardingPanel, {
                    title: _ctx.t('select_integration_title'),
                    description: _ctx.t('select_integration_description'),
                    "is-skippable": true,
                    "on-skip": _ctx.skipToUserInviteFunction,
                    "skip-button-text": _ctx.getSkipButtonText(1)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SelectIntegration, { onIntegrationSelected: _ctx.setSelectedIntegration }, null, 8, ["onIntegrationSelected"])
                    ]),
                    _: 1
                  }, 8, ["title", "description", "on-skip", "skip-button-text"])
                ]),
                _: 1
              }, 8, ["is-visible"]),
              _createVNode(_component_StepperContent, {
                "is-visible": _ctx.activeStep === 2,
                transition: "fade"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OnboardingPanel, {
                    title: _ctx.stepTwoTitle,
                    description: _ctx.stepTwoDescription,
                    "is-skippable": !_ctx.isCustomIntegrationSelected,
                    "on-skip": _ctx.skipToUserInviteFunction,
                    "skip-button-text": _ctx.getSkipButtonText(2)
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isCustomIntegrationSelected)
                        ? (_openBlock(), _createBlock(_component_CustomIntegration, {
                            key: 0,
                            "custom-integration-settings": _ctx.selectedCustomIntegrationSettings,
                            type: _ctx.selectedCustomIntegrationType,
                            "image-url": _ctx.selectedCustomIntegrationSettings.imageUrl || _ctx.selectedIntegration.icon,
                            onBack: _ctx.stepBack,
                            onNext: _ctx.setCustomIntegrationSettings
                          }, null, 8, ["custom-integration-settings", "type", "image-url", "onBack", "onNext"]))
                        : (_openBlock(), _createBlock(_component_SelectTrigger, {
                            key: 1,
                            "selected-integration": _ctx.selectedIntegration,
                            name: _ctx.automationName,
                            "onUpdate:name": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.automationName) = $event)),
                            "active-trigger": _ctx.selectedTrigger,
                            "onUpdate:activeTrigger": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTrigger) = $event)),
                            "shopify-order-origin": _ctx.shopifyOrderOrigin,
                            "onUpdate:shopifyOrderOrigin": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shopifyOrderOrigin) = $event)),
                            "form-impact-source": _ctx.formImpactSource,
                            "onUpdate:formImpactSource": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formImpactSource) = $event)),
                            onSubmit: _ctx.submitTriggerForm,
                            onBack: _ctx.stepBack
                          }, null, 8, ["selected-integration", "name", "active-trigger", "shopify-order-origin", "form-impact-source", "onSubmit", "onBack"]))
                    ]),
                    _: 1
                  }, 8, ["title", "description", "is-skippable", "on-skip", "skip-button-text"])
                ]),
                _: 1
              }, 8, ["is-visible"]),
              _createVNode(_component_StepperContent, {
                "is-visible": _ctx.activeStep === 3,
                transition: "fade"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OnboardingPanel, {
                    title: _ctx.apiKey ? _ctx.t(`api_key_${_ctx.selectedCustomIntegrationType}`) : _ctx.stepThreeTitle,
                    description: _ctx.apiKey ? '' : _ctx.stepThreeDescription
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isCustomIntegrationSelected && !_ctx.apiKey)
                        ? (_openBlock(), _createBlock(_component_CreateApiKey, {
                            key: 0,
                            "custom-integration-setting": _ctx.selectedCustomIntegrationSettings,
                            "custom-integration-type": _ctx.selectedCustomIntegrationType,
                            onBack: _ctx.stepBack,
                            onKeyGenerated: _ctx.handleKeyGeneration
                          }, null, 8, ["custom-integration-setting", "custom-integration-type", "onBack", "onKeyGenerated"]))
                        : (_ctx.isCustomIntegrationSelected && _ctx.apiKey)
                          ? (_openBlock(), _createBlock(_component_CopyApiKey, {
                              key: 1,
                              "custom-integration-type": _ctx.selectedCustomIntegrationType,
                              "api-key": _ctx.apiKey
                            }, null, 8, ["custom-integration-type", "api-key"]))
                          : (_openBlock(), _createBlock(_component_SelectProjects, {
                              key: 2,
                              "selected-integration": _ctx.selectedIntegration,
                              "automation-name": _ctx.automationName,
                              "selected-trigger": _ctx.selectedTrigger,
                              "shopify-order-origin": _ctx.shopifyOrderOrigin,
                              "form-impact-source": _ctx.formImpactSource,
                              "is-new-automation-enabled": _ctx.isNewAutomationEnabled,
                              "on-save-navigation": _ctx.skipToUserInviteFunction,
                              onBack: _ctx.stepBack,
                              onRevertTriggerSelection: _ctx.revertTriggerSelection
                            }, null, 8, ["selected-integration", "automation-name", "selected-trigger", "shopify-order-origin", "form-impact-source", "is-new-automation-enabled", "on-save-navigation", "onBack", "onRevertTriggerSelection"]))
                    ]),
                    _: 1
                  }, 8, ["title", "description"])
                ]),
                _: 1
              }, 8, ["is-visible"]),
              _createVNode(_component_StepperContent, {
                "is-visible": _ctx.renderUserInviteStep && _ctx.activeStep === 4,
                transition: "fade"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_OnboardingPanel, {
                    title: _ctx.t('invite_users_title'),
                    description: _ctx.inviteUsersDescriptionText,
                    "is-skippable": true,
                    "on-skip": _ctx.onSkipUserInvites,
                    "skip-button-text": _ctx.getSkipButtonText(4)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InviteUserOnboard)
                    ]),
                    _: 1
                  }, 8, ["title", "description", "on-skip", "skip-button-text"])
                ]),
                _: 1
              }, 8, ["is-visible"])
            ]),
            _: 1
          }, 8, ["modelValue", "max-value"])
        ]))
      : (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 1 }))
  ]))
}
<template>
  <Loading v-if="loading" />
  <div v-else class="integrations-table">
    <v-data-table
      :items="integrationList"
      :headers="headers"
      v-model:page="page"
      hide-default-footer
      :row-props="{ class: rowClass }"
      :items-per-page="itemsPerPage"
      :search="search"
      height="423"
    >
      <template v-slot:top>
        <v-text-field v-model="search" append-inner-icon="mdi-magnify" />
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <div class="integration-icon-wrapper">
          <v-icon v-if="item.platform === 'custom' && !item.icon" class="integration-icon custom">
            mdi-puzzle-outline
          </v-icon>
          <img v-else class="integration-icon" :src="item.icon" :alt="item.platform" />
        </div>
      </template>
      <template v-slot:[`item.platform`]="{ item }">
        <p class="mb-0">
          {{ getIntegrationPlatformName(item.platform) }}
        </p>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <span @click.prevent="copyTextToClipboard(item.id)">
          {{ shortenText(item.id) }}
          <v-icon :size="18" class="copy ml-1">
            {{ isCopied ? 'mdi-check-circle' : 'mdi-content-copy' }}
          </v-icon>
        </span>
      </template>
      <template v-slot:[`item.automationNumber`]="{ item }">
        <span class="mb-0 automations-pill">
          {{
            isCustom
              ? tc('triggers', item.automationNumber)
              : tc('automations', item.automationNumber)
          }}
        </span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span :class="['status-pill', getIntegrationStatus(item)]">
          {{ t(getIntegrationStatus(item)) }}
        </span>
      </template>
      <template v-slot:[`item.context`]="{ item }">
        <v-menu v-if="mode === 'manage'">
          <template v-slot:activator="{ props }">
            <button class="context-menu-button" v-bind="props">
              <v-icon>mdi-dots-horizontal</v-icon>
            </button>
          </template>
          <div class="drawer">
            <button
              v-if="hasIntegrationError(item)"
              class="drawer-button"
              @click="handleIntegrationError(item)"
            >
              <v-icon class="drawer-icon"> mdi-lock-reset </v-icon>{{ t('reauth') }}
            </button>
            <button class="drawer-button" @click="selectIntegration(item)">
              <v-icon class="drawer-icon"> mdi-creation </v-icon>{{ t('add_automation') }}
            </button>
            <button
              v-if="isIntegrationSyncable(item)"
              class="drawer-button"
              @click="openSyncDialog(item)"
            >
              <v-icon class="drawer-icon"> mdi-sync </v-icon>{{ $t('CommonUi.sync') }}
            </button>
            <button class="drawer-button" @click="openDeleteIntegrationDialog(item)">
              <v-icon class="drawer-icon"> mdi-delete-outline </v-icon>{{ $t('CommonUi.delete') }}
            </button>
          </div>
        </v-menu>
        <button v-else class="select-button" @click="selectIntegration(item)">
          {{ t('select') }}
        </button>
      </template>
    </v-data-table>
    <div class="table-footer">
      <v-pagination :total-visible="3" v-model="page" :length="pageCount" />
    </div>
  </div>
</template>

<script lang="ts">
import type {
  IntegrationPlatform,
  IntegrationTableData,
  IntegrationOption,
} from '@/store/integrations'
import type { Emptyable } from '@/helpers/interfaces'
import { CUSTOM_SOURCES, INTEGRATOR_SOURCES } from '@/helpers/interfaces'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import type { DialogUpdatePayload } from '@/store/dialog'
import { Dialogs } from '@/store/dialog'
import { CUSTOM_INTEGRATION_TYPES } from '@/components/onboarding/CustomIntegration.vue'
import { includes } from '@/helpers/parsers'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import type { Account } from '@/store'
import Loading from '@/components/tools/Loading.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN } from '@/views/IntegrationsAutomations/EmailDataSyncView.vue'
import { testIntegrationWriteDirection } from '@api/index'
import { isDemoEnvironment } from '@/helpers/constants'

export default defineComponent({
  name: 'IntegrationsTable',
  emits: ['select'],
  mixins: [IntegrationsMixin, Utils],
  components: {
    Loading,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          title: '',
          align: 'start',
          sortable: false,
          key: 'image',
          class: 'data-table-header',
          width: 90,
        },
        {
          title: 'Integration',
          align: 'start',
          sortable: true,
          key: 'platform',
          class: 'data-table-header',
          width: '120px',
        },
        {
          title: 'Added',
          align: 'start',
          key: 'createdAt',
          sortable: true,
          class: 'data-table-header',
          width: '122px',
        },
        {
          title: 'Unique name',
          align: 'start',
          key: 'name',
          sortable: true,
          class: 'data-table-header',
          width: '140px',
        },
        {
          title: 'Integration ID',
          align: 'start',
          key: 'id',
          sortable: true,
          class: 'data-table-header',
          width: '145px',
        },
        {
          title: 'Linked automations',
          align: 'start',
          key: 'automationNumber',
          sortable: false,
          class: 'data-table-header',
          width: '160px',
        },
        {
          title: 'Status',
          align: 'start',
          key: 'status',
          sortable: true,
          class: 'data-table-header',
          width: '150px',
        },
        {
          title: '',
          align: 'end',
          key: 'context',
          sortable: false,
          class: 'data-table-header',
        },
      ],
      platform: '',
      page: 1,
      itemsPerPage: 5,
      loading: false,
    } as {
      search: string
      platform: Emptyable<IntegrationPlatform>
      page: number
      itemsPerPage: number
      loading: boolean
      headers: {
        title: string
        align: string
        sortable: boolean
        key: string
        class: string
        width: number | string
      }[]
    }
  },
  computed: {
    integrationList(): IntegrationTableData[] {
      return this.getIntegrationsForTable(this.platform)
    },
    integrationOptionList(): IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    isCustom(): boolean {
      return includes(CUSTOM_SOURCES, this.platform) || includes(INTEGRATOR_SOURCES, this.platform)
    },
    getIntegrationsForTable(): (
      platform: Emptyable<IntegrationPlatform>,
    ) => IntegrationTableData[] {
      return this.$store.getters['getIntegrationsForTable']
    },
    pageCount(): number {
      return Math.ceil(this.integrationList.length / this.itemsPerPage)
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  async created() {
    await this.mountHotGlue()
    await this.setIntegrations()
    await this.fetchIntegrationOptions()

    this.platform = this.$route.query.platform as IntegrationPlatform

    if (this.isCustom) {
      this.headers[4].title = 'Triggers'
    }
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`IntegrationsTable.${key}`, params ?? {})
    },
    tc(key: string, count: number) {
      return this.$t(`IntegrationsTable.${key}`, count)
    },
    rowClass() {
      return 'data-table-row'
    },
    openDeleteIntegrationDialog(integration: IntegrationTableData) {
      this.openDialog({
        name: Dialogs.TEMPLATE.DELETE,
        texts: {
          title: this.t('delete_integration_dialog_title'),
          description: this.t('delete_integration_description', { integration: integration.name }),
          primaryButtonText: this.t('delete_integration'),
          secondaryButtonText: this.$t('CommonUi.cancel'),
          deleteCheckboxLabel: this.t('delete_integration_checkbox_label'),
        },
        options: {
          persistent: true,
        },
        actions: {
          onPrimaryButtonClick: async () => {
            includes(CUSTOM_INTEGRATION_TYPES, integration.platform)
              ? await this.deleteSource({ customIntegrationId: integration.id })
              : await this.deleteIntegration(integration.id)
            if (!this.getIntegrationsForTable(this.platform).length) {
              await this.$router.push({
                path: '/add-impact/manage-integrations-automations/integrations',
              })
            }
          },
        },
      })
    },
    selectIntegration(integration: IntegrationTableData) {
      this.$emit('select', integration)
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
    openDialog(dialog: DialogUpdatePayload): Promise<void> {
      return this.$store.dispatch('openDialog', dialog)
    },
    deleteIntegration(integrationId: string): Promise<void> {
      return this.$store.dispatch('deleteIntegration', integrationId)
    },
    fetchIntegrationOptions(): Promise<void> {
      return this.$store.dispatch('fetchIntegrationOptions')
    },
    deleteSource(payload: { customIntegrationId: string }): Promise<void> {
      return this.$store.dispatch('deleteSource', payload)
    },
    setSelectedIntegrationForDataSync(
      integration: SelectedIntegration | IntegrationTableData | null,
    ) {
      return this.$store.commit('setSelectedIntegrationForDataSync', integration)
    },
    async openSyncDialog(item: IntegrationTableData) {
      this.setSelectedIntegrationForDataSync(item)
      await this.$router.push({
        name: 'EmailDataSync',
      })
    },
    getIntegrationOptionByName(integration: IntegrationTableData) {
      return this.integrationOptionList.find((option) => {
        if (isDemoEnvironment) {
          return `${option.name.toLowerCase()} demo` === integration.name.toLowerCase()
        } else {
          return option.name.toLowerCase() === integration.platform.toLowerCase()
        }
      })
    },
    isIntegrationSyncable(integration: IntegrationTableData) {
      const integrationOption = this.getIntegrationOptionByName(integration)
      return integrationOption?.writeDirection && integrationOption.writeDirection.isAvailable
    },
    async navigateToNextStep(item: IntegrationOption) {
      const selectedIntegration = this.integrationList.find(
        (integration) => integration.platform.toLowerCase() === item.name.toLowerCase(),
      )

      if (selectedIntegration) {
        await testIntegrationWriteDirection(selectedIntegration.id)
        await this.setIntegrations()
      }

      this.loading = false
    },
    handleIntegrationPopupClose() {
      this.loading = false
    },
    handleIntegrationPopupOpen() {
      this.loading = false
    },
    hasIntegrationError(integration: IntegrationTableData): boolean | undefined {
      return (
        integration.status === 'error' ||
        (integration.writeDirection?.testStatus?.status === 'error' &&
          integration.writeDirection?.testStatus?.description ===
            INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN) ||
        (integration.writeDirection?.status?.status === 'error' &&
          integration.writeDirection?.status?.description ===
            INTEGRATION_WRITE_DIRECTION_TEST_FORBIDDEN)
      )
    },
    handleIntegrationError(integration: IntegrationTableData) {
      if (integration.statusUrl) {
        window.open(integration.statusUrl, '_blank')
        return
      }

      const integrationOption = this.getIntegrationOptionByName(integration)

      if (integrationOption) {
        this.loading = true
        this.setupIntegration(
          this.account?.accountId,
          integrationOption,
          this.navigateToNextStep,
          this.handleIntegrationPopupClose,
          this.handleIntegrationPopupOpen,
        )
      }
    },
    getIntegrationStatus(item: IntegrationTableData) {
      return item.writeDirection?.status?.status === 'active' ||
        item.writeDirection?.status?.status === 'error'
        ? this.hasIntegrationError(item)
          ? 'error'
          : item.writeDirection?.status?.status
        : item.status
    },
  },
  props: {
    mode: {
      default: 'manage',
      type: String as PropType<'select' | 'manage'>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.integration-icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}

.integration-icon {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.integration-icon.custom {
  color: var(--ui-blue);
  background: url('../../assets/backgrounds/icon-background.png') center/contain no-repeat;
}

.automations-table {
  border-radius: 10px;
  overflow: hidden;
}

.impact-pill-wrapper {
  display: flex;
  gap: 10px;
}

.ongoing-icon {
  color: var(--font-color-primary);
  font-size: 16px;
}

.status-pill {
  border-radius: 8px;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid var(--gray-light);
  color: var(--gray-light);
  margin: 0;
  text-transform: capitalize;
}

.status-pill.active,
.status-pill.success,
.status-pill.scheduled {
  color: var(--ui-green);
  border: 1px solid var(--ui-green);
}

.status-pill.error,
.status-pill.scheduled {
  color: var(--ui-red) !important;
  border: 1px solid var(--ui-red) !important;
  background: transparent !important;
}

:deep(.data-table-row) {
  height: 75px;
}

:deep(.data-table-row > td.text-start),
:deep(.data-table-row > td.text-end) {
  border-bottom: none !important;
}

:deep(.data-table-row:nth-child(odd)) {
  background-color: var(--ui-green-light-20);
}

:deep(.data-table-row:nth-child(odd):hover) {
  background-color: var(--ui-green-light-20) !important;
}

:deep(.data-table-row:nth-child(even):hover) {
  background-color: white !important;
}

:deep(.v-data-table-header tr) {
  background-color: var(--ui-beige);
}

:deep(.v-data-table-header th) {
  border-bottom: none !important;
}

.trigger-icon {
  color: var(--font-color-primary);
  font-size: 30px;
}

.drawer {
  background-color: #ffffff;
  padding: 5px;
}

.drawer-button {
  align-items: center;
  display: flex;
  border-radius: var(--border-radius-big);
  text-align: left;
  padding: 5px;
  min-width: 100px;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: var(--font-color-primary);
}

.drawer-icon {
  font-size: 18px;
  color: var(--font-color-primary);
  margin-right: 10px;
  flex-shrink: 0;
}

.drawer-button:hover {
  background-color: var(--ui-green-light-20);
}

.table-footer {
  background-color: var(--ui-beige);
  border-radius: 0 0 10px 10px;
  padding: 10px;
}

.copy {
  cursor: pointer;
}

:deep(.v-pagination) {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

:deep(.v-pagination li > button.v-pagination__item--active.v-pagination__item) {
  background-color: var(--ui-white) !important;
  border: 2px solid var(--ui-green);
  color: var(--ui-green) !important;
}

:deep(.v-pagination li > button) {
  height: 50px;
  width: 50px;
  border-radius: 16px;
  box-shadow: none;
  color: var(--ui-green) !important;
}

:deep(.v-pagination li:first-child > button),
:deep(.v-pagination li:last-child > button) {
  background-color: white;
  color: var(--ui-green);
}

:deep(.v-pagination li > button:hove)r,
:deep(.v-pagination li > button:hover) {
  opacity: 0.8;
  border: 2px solid var(--ui-green) !important;
}

:deep(.v-pagination li:first-child > button > i.v-icon),
:deep(.v-pagination li:last-child > button > i.v-icon) {
  font-size: 30px !important;
  color: var(--ui-green) !important;
}

:deep(.v-pagination li:first-child) {
  margin-right: auto;
}

:deep(.v-pagination li:last-child) {
  margin-left: auto;
}

.automations-pill {
  border-radius: 8px;
  background-color: var(--ui-beige);
  padding: 2px 4px;
  font-size: 14px;
  line-height: 18px;
}

.select-button {
  padding: 2px 10px;
  border-radius: 8px;
  background-color: var(--ui-green);
  color: var(--ui-beige);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>

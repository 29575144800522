import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49b3ee18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quick-menu" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "menu-wrapper" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, { "min-width": "200" }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("img", _mergeProps({
          class: "quick-menu-open",
          src: require('@/assets/icons/quick-menu.svg'),
          alt: "menu"
        }, props), null, 16, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, a) => {
            return (_openBlock(), _createElementBlock("div", {
              key: a,
              class: _normalizeClass(item.value === 'remindNotActive' ? 'menu-item-not-active' : 'menu-item'),
              onClick: ($event: any) => (_ctx.quickMenuClick(item.value))
            }, [
              _createElementVNode("img", {
                src: item.image,
                class: "quick-menu-icon",
                alt: "icon"
              }, null, 8, _hoisted_5),
              _createTextVNode(" " + _toDisplayString(item.label), 1)
            ], 10, _hoisted_4))
          }), 128))
        ])
      ]),
      _: 1
    })
  ]))
}